<template>
  <header>
    <div class="container">
      <section class="total">
        <div>
          <span class="title-mini">total games:</span>
          {{ gameData.total_games }}
        </div>
        <div>
          <span class="title-mini">duration:</span> {{ gameData.duration }}
        </div>
      </section>
      <section class="winners">
        <span class="title-mini">wins:&nbsp;</span>
        <div v-for="winner of gameData.winners" class="winner">
          {{ winner.name }}: {{ winner.wins }}
        </div>
      </section>
      <section class="button">
        <button class="stateBtn">
          {{ state === 'frags' ? 'INFORMATION' : 'FRAGS' }}
        </button>
      </section>
    </div>
  </header>
</template>
<script>
export default {
  props: {
    gameData: {
      type: Object,
      required: true
    },
    state: {
      type: String,
      required: true
    }
  }
};
</script>
